input {
    margin: 0px 5px;
}

table {
    border-collapse: collapse;
}

img {
    border: 0px;
}

.numberInput {
    min-width: 30px;
}

.center {
    text-align: center;
}

.fullHeightScroll {
    height: 100vh;
    overflow-y: auto;
}

.icon {
    width: 50px;
    height: 50px;
    display: block;
}

.filters {
    width: 175px;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}