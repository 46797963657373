li { 
    background: #ffeebd; 
}

li:nth-child(odd) { 
    background: #dac2b4; 
}

#monsterSearch  {
    width: 100%;
    margin: 0px;
    box-sizing: border-box;
}